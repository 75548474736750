import React, { useState } from "react";
import "./Novel.css";
import novelImage from "../../assets/Novel.webp";
import Footer from "../Footer";

function Novel() {
  // track if the image has loaded
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div>
      <div className="coming-soon">COMING SOON</div>
      <div className="novel-container">
        {/* Placeholder until the image loads */}
        <div className={`image-placeholder ${imageLoaded ? "hidden" : ""}`}>
          {!imageLoaded && <span>Loading...</span>}
        </div>
        <img
          src={novelImage}
          alt="Novel Cover"
          className={`novel-image ${imageLoaded ? "loaded" : "loading"}`}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    </div>
  );
}

export default Novel;
