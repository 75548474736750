import React from "react";
import "./MainPanel.css";

const MainPanel = () => {
  return (
    <div className="terminal">
      <p>Welcome to Apex Ape.</p>
      <p>Waiting for the next round to start...</p>
    </div>
  );
};

export default MainPanel;
