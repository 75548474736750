import React from "react";
import "./SidePanel.css";

const SidePanel = () => {
  return (
    <div>
      <div className="sidepanelsm">
        <h1 className="panel-title">Rewards</h1>
        <p className="panel-text">
          Win = 1000 $Adamas <br />
          Family Win = 100 $Adamas <br />
          Faction Win = 10 $Adamas <br /> <br />
          Kill = 10 $Adamas <br />
          Family Kills = 1 $Adamas <br /> <br />
        </p>
        <h1 className="panel-title">Info</h1>
        <p className="panel-text-lg">
          Apex Ape is a Battle Royale event that takes place every 48 hours,
          featuring 3,000 apes competing to be the last one standing. Success
          depends heavily on individual stats, with better stats boosting
          survival chances. This intense and unpredictable competition tests the
          apes’ skills and strategies in a high-stakes environment. <br />{" "}
          <br />
        </p>
        <h1 className="panel-title">How-To-Play</h1>
        <p className="panel-text">
          You Don't <br />
        </p>
      </div>
      <div className="sidepanellg">
        <h1 className="panel-title">Leaderboard</h1>
      </div>
      <div className="sidepanelsm2"></div>
    </div>
  );
};

export default SidePanel;
