import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import apesData from "../../Encrypted.json"; // Import the JSON data directly
import images from "../../utils/ImportImages"; // Import the images from the utility file
import "./NFTDetails.css";
import { ReactComponent as LeftArrowIcon } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/right-arrow.svg";
import Footer from "../Footer";

const NFTDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [nft, setNft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNft(null);
    setError(null);
    setLoading(true);

    const fetchNFTData = () => {
      const apeData = apesData.find((ape) => ape.id === id);

      if (!apeData) {
        setError("NFT not found");
        setLoading(false);
        return;
      }

      // Set the main image URL using the encrypted ID directly
      apeData.image = `https://storage.googleapis.com/apelantia/pixelated/${apeData.id}.png`;

      // Process relatives array to include image URLs from the imported images or fallback to Google Cloud
      if (Array.isArray(apeData.relatives) && apeData.relatives.length > 0) {
        apeData.relatives = apeData.relatives.map((relative) => ({
          ...relative,
          // Use the imported image if it exists, otherwise fall back to the Google Cloud link
          image: images[relative.id]
            ? images[relative.id]
            : `https://storage.googleapis.com/apelantia/pixelated/${relative.id}.png`,
        }));
      }

      // Set the state with the final data
      setNft(apeData);
      setLoading(false);
    };

    fetchNFTData();
  }, [id]);


  const handleNavigation = (direction) => {
    const currentIndex = apesData.findIndex((ape) => ape.id === id);
    if (currentIndex !== -1) {
      const newIndex =
        direction === "left"
          ? (currentIndex - 1 + apesData.length) % apesData.length
          : (currentIndex + 1) % apesData.length;
      const newId = apesData[newIndex].id;
      navigate(`/profile/${newId}`);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handleNavigation("left");
      } else if (event.key === "ArrowRight") {
        handleNavigation("right");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [id]);

  const LoadingPlaceholder = ({ text }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "50%",
        fontSize: "24px",
        color: "#202020",
        fontFamily: "Poppins, monospace",
        backgroundColor: "#ccc",
        textAlign: "center",
        position: "relative",
      }}
    >
      {text}
    </div>
  );

  if (loading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#202020",
          fontSize: "24px",
          fontFamily: "Poppins, monospace",
        }}
      >
        {error}
      </div>
    );
  }

  if (!nft) return <p>No NFT data found.</p>;

  let factionColor = "#000";
  if (nft && nft.faction) {
    switch (nft.faction.toLowerCase()) {
      case "mafia":
        factionColor = "darkred";
        break;
      case "rebel":
        factionColor = "green";
        break;
      case "royal":
        factionColor = "gold";
        break;
      default:
        factionColor = "#EBEBEB";
        break;
    }
  }

  return (
    <div style={{ fontFamily: "Poppins, sans-serif" }} key={id}>
      <div className="nft-details-container">
        <div className="nft-image-container">
          {nft && nft.image ? (
            <img
              src={nft.image}
              alt={nft ? nft.name : "NFT"}
              className="nft-image"
            />
          ) : (
            <LoadingPlaceholder text={nft ? nft.name : "Loading NFT..."} />
          )}
        </div>

        <div className="nft-info-container">
          <div className="nft-info-header">
            <div className="nft-info-section">
              <h2>Name: {nft ? nft.name : ""}</h2>
              <h2>ID: {nft ? "Hidden" : ""}</h2>
              <h2>
                Faction:{" "}
                <span style={{ color: factionColor, fontSize: "1em" }}>
                  {nft ? nft.faction : "Unknown Faction"}
                </span>
              </h2>
            </div>
            <div className="nft-info-section">
              <h2>Role: {nft ? nft.role : "Unknown Role"}</h2>
              <h2>Birthdate: {nft ? nft.birthdate : "Unknown"}</h2>
            </div>
          </div>

          <div className="nft-section-title">
            <div className="navigation-arrows">
              <div
                className="navigation-link"
                onClick={() => handleNavigation("left")}
              >
                <LeftArrowIcon className="arrow-icon" />
              </div>
              <h2>Description</h2>
              <div
                className="navigation-link"
                onClick={() => handleNavigation("right")}
              >
                <RightArrowIcon className="arrow-icon" />
              </div>
            </div>
          </div>
          <div className="typewriter-container">
            {nft ? (
              <Typewriter
                options={{
                  strings: nft.description,
                  autoStart: true,
                  delay: 20,
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="nft-attributes-siblings">
            <div className="nft-attributes">
              <h2 className="nft-section-title">Attributes</h2>
              <ul>
                {nft && nft.attributes
                  ? nft.attributes.map((attribute, index) => (
                      <li key={index}>
                        {attribute.trait_type}: {attribute.value}
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="nft-siblings">
              <h2 className="nft-section-title">Relatives</h2>
              <ul>
                {nft && nft.relatives.length > 0 ? (
                  nft.relatives.map((relative) => (
                    <li
                      key={relative.id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {relative.image ? (
                        <img
                          src={relative.image}
                          alt={`Relative ${relative.name}`}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#ccc",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      <Link to={`/profile/${relative.id}`}>
                        {relative.name}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li>No relatives found.</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NFTDetails;
