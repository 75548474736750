import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Howl } from "howler";
import musicalNoteIcon from "../assets/musical-note.png"; // Import the play icon
import pauseIcon from "../assets/pause.png"; // Import the pause icon
import sound1File from "../assets/sounds/sound1.mp3";

// Singleton pattern for Howl instance
let sound = null;

const Button = styled.button`
  width: 50px;
  height: 50px;

  border: 2px solid #202020; // Default stroke color
  margin: 0;
  padding: 0;
  background-color: #ebebeb;
  z-index: 1000;

  font-size: 24px;
  line-height: 50px;
  text-align: center;

  position: fixed;
  left: 20px;
  bottom: 20px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s ease,
    border-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #9e0808;
  }

  &:active {
    transform: scale(0.9);
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Sound = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Initialize the Howl instance only once
    if (!sound) {
      sound = new Howl({
        src: [sound1File],
        html5: true,
        loop: true,
      });
    }

    // Cleanup when component unmounts
    return () => {
      if (sound.playing()) {
        sound.stop();
      }
      setIsPlaying(false);
    };
  }, []);

  const togglePlay = () => {
    if (!isPlaying) {
      sound.play();
    } else {
      sound.pause();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Button onClick={togglePlay}>
      <Icon
        src={isPlaying ? pauseIcon : musicalNoteIcon}
        alt="Sound Control Icon"
      />
    </Button>
  );
};

export default Sound;
