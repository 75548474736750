import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Navigation from "./components/Navigation";
import Home from "./components/sections/Home";
import HappyBirthday from "./components/sections/HappyBirthday";
import Team from "./components/sections/Team";
import ApesPage from "./components/sections/ApesPage"; // Update the import path
import MapPage from "./components/sections/MapPage";
import NovelPage from "./components/sections/Novel";
import Play from "./components/sections/PlayPage";
import NFTDetails from "./components/sections/NFTDetails";
import ScrollToTop from "./components/ScrollToTop";
import Sound from "./components/Sound"; // Import the Sound component

const App = () => {
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  return (
    <ThemeProvider theme={light}>
      <GlobalStyles />
      <Navigation />
      <Sound /> {/* Soound */}
      {isHomePage && (
        <>
          <Home />
          <HappyBirthday />
          <Team />
        </>
      )}
      <Routes>
        <Route path="/play" element={<Play />} />
        <Route path="/profile" element={<ApesPage />} />{" "}
        {/* Update the route path */}
        <Route path="/map" element={<MapPage />} />
        <Route path="/novel" element={<NovelPage />} />
        <Route path="/profile/:id" element={<NFTDetails />} />
        <Route path="/" element={<></>} />
      </Routes>
      <ScrollToTop />
    </ThemeProvider>
  );
};

export default App;
