const assetsList = [
  "Accessories - Diamond Stud",
  "Accessories - Gold Hoop",
  "Accessories - Silver Hoop",
  "Eye Color - Black",
  "Eye Color - Blue",
  "Eye Color - Green",
  "Eye Color - Lava",
  "Eye Color - Red",
  "Eyes - Bored",
  "Eyes - Bored Stoned",
  "Eyes - Bruised",
  "Eyes - High Wide",
  "Eyes - Neutral",
  "Eyes - Neutral Stoned",
  "Eyes - Wide",
  "Fur - Base Brown",
  "Fur - Black",
  "Fur - Blast Bronze",
  "Fur - Cyan",
  "Fur - Gray",
  "Fur - Pearly Purple",
  "Fur - Rifle Green",
  "Fur - White",
  "Glasses - Circular Glasses",
  "Glasses - Monocles",
  "Hat - Army Hat",
  "Hat - Backwards Cap",
  "Hat - Beanie",
  "Hat - Black Bandana",
  "Hat - Black Beret",
  "Hat - Black Cap",
  "Hat - Bowler",
  "Hat - Cowboy",
  "Hat - Cowboy Black",
  "Hat - Crown",
  "Hat - Fedora",
  "Hat - Gatsby Hat",
  "Hat - Gold Rose Cap",
  "Hat - Guevara Hat",
  "Hat - Military Hat",
  "Hat - Panama",
  "Hat - Pirate Hat",
  "Hat - Rose Cap",
  "Hat - Top Hat",
  "Hat - Tweed Cap",
  "Hat - White Beret",
  "Mouth - Angry",
  "Mouth - Angry Cigar",
  "Mouth - Angry Cigarette",
  "Mouth - Angry Pipe",
  "Mouth - Bandana",
  "Mouth - Bored",
  "Mouth - Bored Cigar",
  "Mouth - Bored Cigarette",
  "Mouth - Bored Pipe",
  "Mouth - Gold Smile",
  "Mouth - Mask",
  "Mouth - Mustache",
  "Mouth - Mustache Cigar",
  "Mouth - Mustache Cigarette",
  "Mouth - Mustache Pipe",
  "Mouth - Neutral",
  "Mouth - Neutral Cigar",
  "Mouth - Neutral Cigarette",
  "Mouth - Neutral Pipe",
  "Mouth - Smile",
  "Mouth - Smile Cigar",
  "Mouth - Smile Cigarette",
  "Mouth - Smile Pipe",
  "Mouth - SmilePipe",
  "Mouth - Yawn",
  "Outfit - Arab Prince",
  "Outfit - Army Suit",
  "Outfit - Black Hoodie",
  "Outfit - Black Jacket Shirt",
  "Outfit - Black Shirt",
  "Outfit - Black Suit Shirt",
  "Outfit - Black Tuxedo",
  "Outfit - Blue Tuxedo",
  "Outfit - Brown Jacket",
  "Outfit - Dark Monarch",
  "Outfit - Dark Prince",
  "Outfit - England Guard",
  "Outfit - European Guard Black",
  "Outfit - European Guard White",
  "Outfit - France Suit",
  "Outfit - Funky Suit",
  "Outfit - Gentleman Suit",
  "Outfit - George V",
  "Outfit - Green Shirt",
  "Outfit - Grey Hoodie",
  "Outfit - Grey Tanktop",
  "Outfit - Italian Gangster",
  "Outfit - Italian Soldier",
  "Outfit - Italian Suit",
  "Outfit - Leather Jacket",
  "Outfit - Military Suit",
  "Outfit - Northface Jacket",
  "Outfit - Nurse",
  "Outfit - PAC Jacket",
  "Outfit - Pera Muzesi",
  "Outfit - Pirate Suit",
  "Outfit - Police",
  "Outfit - Red Rose Jacket",
  "Outfit - Richmod Tuxedo",
  "Outfit - Roman Toga",
  "Outfit - Royal Commander",
  "Outfit - Royal Grid",
  "Outfit - Royal Red Suit",
  "Outfit - Royal Soldier",
  "Outfit - Royal Suit",
  "Outfit - Shelby suit",
  "Outfit - Spanish Royal Suit",
  "Outfit - Swift",
  "Outfit - Thawb",
  "Outfit - Thawbtop",
  "Outfit - Turtleneck",
  "Outfit - Vest",
  "Outfit - Viking King",
  "Outfit - White Suit",
];

export default assetsList;
