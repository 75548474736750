import React from 'react';
import styled from 'styled-components';
import apeImage from '../assets/Nfts/ape.webp';

const ImageContainer = styled.div`
  width: 150%; // Wide width to allow full coverage if needed
  height: auto; // Height adjusts to content
  display: flex; // Enables flexbox layout
  justify-content: flex-start; // Aligns child to the left
  align-items: center; // Vertically centers the image
  padding-left: 10px; // Padding to prevent image sticking to the screen edge

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 64em) {
    min-width: 40vh;
    padding-left: 10px; // Ensures padding is maintained on smaller screens
  }
`;

const CoverImage = () => {
  return (
    <ImageContainer>
        <img src={apeImage} alt="Ape" />
    </ImageContainer>
  )
}

export default CoverImage;
