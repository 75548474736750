import React, { useState, useEffect, useRef } from "react";
import SearchBar from "../SearchBar";
import NFTList from "../NFTList";
import AssetModal from "../AssetModal"; // Import the AssetModal
import "./ApesPage.css";
import { useWallet } from "../../contexts/WalletProvider";
import apesData from "../../Encrypted.json"; // Import the JSON data directly
import calculateAge from "../../utils/calculateAge"; // Import the calculateAge function
import assetsList from "../../constants/assetList"; // Import the assetList from constants

const ApesPage = () => {
  const [nfts, setNfts] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);
  const [userNfts, setUserNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("explore");
  const [factionFilter, setFactionFilter] = useState("");
  const [selectedAssets, setSelectedAssets] = useState({}); // State to manage selected assets
  const [selectedFaction, setSelectedFaction] = useState(null); // State to manage selected faction
  const [sortBy, setSortBy] = useState("All"); // Default state
  const [isFactionDropdownOpen, setIsFactionDropdownOpen] = useState(false);
  const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false); // New state for AssetModal
  const [page, setPage] = useState(1);
  const { walletAddress } = useWallet();
  const nftsPerPage = 40;

  const factionDropdownRef = useRef(null);
  const assetDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      factionDropdownRef.current &&
      !factionDropdownRef.current.contains(event.target)
    ) {
      setIsFactionDropdownOpen(false);
    }
    if (
      assetDropdownRef.current &&
      !assetDropdownRef.current.contains(event.target)
    ) {
      setIsAssetDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const loadNFTs = () => {
    try {
      const offset = (page - 1) * nftsPerPage;
      let data = apesData;

      if (selectedFaction) {
        data = data.filter(
          (ape) => ape.faction.toLowerCase() === selectedFaction.toLowerCase()
        );
      }

      if (Object.keys(selectedAssets).length > 0) {
        data = data.filter((ape) =>
          Object.entries(selectedAssets).every(([traitType, value]) =>
            ape.attributes.some(
              (attr) =>
                attr.trait_type.toLowerCase() === traitType.toLowerCase() &&
                attr.value.toLowerCase() === value.toLowerCase()
            )
          )
        );
      }

      const paginatedNfts = data
        .slice(offset, offset + nftsPerPage)
        .map((nft) => ({
          imageUrl: `https://storage.googleapis.com/apelantia/pixelated/${nft.id}.png`, // Use the encrypted ID directly
          name: nft.name, // Name is already trimmed in the JSON
          id: nft.id,
          age: calculateAge(nft.birthdate), // Calculate the age using the birthdate
          faction: nft.faction, // Include faction in the data
          role: nft.role, // Include role in the data
        }));


      setNfts((prevNfts) =>
        page === 1 ? paginatedNfts : [...prevNfts, ...paginatedNfts]
      );
      setFilteredNfts((prevNfts) =>
        page === 1 ? paginatedNfts : [...prevNfts, ...paginatedNfts]
      );
      setLoading(false);
    } catch (err) {
      console.error(`Error loading NFTs: ${err.message}`);
      setError(`Error loading NFTs: ${err.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true); // Set loading state to true when filters change
    loadNFTs();
  }, [page, selectedFaction, selectedAssets]);

  useEffect(() => {
    const dropdown = document.querySelector(".asset-items");

    if (dropdown) {
      const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropdown.scrollTop += e.deltaY;
      };

      dropdown.addEventListener("wheel", preventScroll);

      // Cleanup the event listener when the component unmounts
      return () => {
        dropdown.removeEventListener("wheel", preventScroll);
      };
    }
  }, [isAssetDropdownOpen]);

  const getFactionColor = (faction) => {
    switch (faction?.toLowerCase()) {
      case "mafia":
        return "darkred";
      case "rebel":
        return "green";
      case "royal":
        return "gold";
      default:
        return "#EBEBEB";
    }
  };

  const handleFactionSortChange = (value) => {
    setLoading(true); // Set loading state to true
    setSelectedAssets({}); // Reset selected assets
    setSelectedFaction(value === "All" ? null : value); // Update faction
    setSortBy(value); // Update sortBy to reflect the selected faction
    setPage(1); // Reset to the first page when changing the sort option
  };

  const handleAssetFilterChange = (value) => {
    setLoading(true); // Set loading state to true
    setSelectedFaction(null); // Reset faction filter
    setSelectedAssets((prevFilters) => {
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return { ...prevFilters, [value]: value };
      }
    });
    setPage(1); // Reset to the first page when changing filters
  };

  const handleFactionDropdownClick = () => {
    setIsFactionDropdownOpen(!isFactionDropdownOpen);
  };

  const handleAssetDropdownClick = () => {
    setIsAssetDropdownOpen(!isAssetDropdownOpen);
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOpenAssetModal = () => {
    setIsAssetModalOpen(true);
  };

  const handleCloseAssetModal = () => {
    setIsAssetModalOpen(false);
  };

  if (loading && page === 1)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#202020",
          fontSize: "24px",
          fontFamily: "VT323, monospace",
        }}
      >
        Loading...
      </div>
    );
  if (error)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#202020",
          fontSize: "24px",
          fontFamily: "VT323, monospace",
        }}
      >
        {error}
      </div>
    );

  const hasMoreNFTs = filteredNfts.length < apesData.length;
  const factionColor = getFactionColor(sortBy);

  return (
    <div className="apes-page-container">
      <div className="tab-navigation">
        <button
          className={`tab-button ${activeTab === "explore" ? "active" : ""}`}
          onClick={() => setActiveTab("explore")}
        >
          Explore
        </button>
        <button
          className={`tab-button ${activeTab === "profile" ? "active" : ""}`}
          onClick={() => setActiveTab("profile")}
        >
          Profile
        </button>
      </div>
      {activeTab === "explore" && (
        <div className="search-and-sort">
          <SearchBar />
          <div className="faction-filters" ref={factionDropdownRef}>
            <div className="faction-select">
              <div
                className="faction-selected"
                onClick={handleFactionDropdownClick}
                style={{ color: factionColor }} // Apply the faction color here
              >
                {sortBy}
              </div>
              {isFactionDropdownOpen && (
                <div className="faction-items">
                  <div onClick={() => handleFactionSortChange("All")}>All</div>
                  <div
                    className="mafia-option"
                    onClick={() => handleFactionSortChange("Mafia")}
                  >
                    Mafia
                  </div>
                  <div
                    className="rebel-option"
                    onClick={() => handleFactionSortChange("Rebel")}
                  >
                    Rebel
                  </div>
                  <div
                    className="royal-option"
                    onClick={() => handleFactionSortChange("Royal")}
                  >
                    Royal
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="asset-filters" ref={assetDropdownRef}>
            <div className="asset-select" onClick={handleOpenAssetModal}>
              <div className="asset-selected">
                {Object.keys(selectedAssets).length > 0 || selectedFaction
                  ? `Selected (${
                      Object.keys(selectedAssets).length +
                      (selectedFaction ? 1 : 0)
                    })`
                  : "Select Assets"}
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "explore" ? (
        <NFTList nftCardsData={filteredNfts} loading={loading} />
      ) : userNfts.length > 0 ? (
        <NFTList nftCardsData={userNfts} loading={loading} />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10vw",
            alignItems: "center",
            height: "100vh",
            color: "#202020",
            fontSize: "24px",
            fontFamily: "VT323, monospace",
          }}
        >
          <div style={{ fontSize: "32px", marginBottom: "20px" }}>
            You do not own any Apes
          </div>
          <a
            href="https://blastr.xyz/apelantia"
            target="_blank"
            rel="noopener noreferrer"
            className="MintButton"
          >
            Mint
          </a>
        </div>
      )}
      {activeTab === "explore" && !loading && hasMoreNFTs && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Load More
        </button>
      )}
      {isAssetModalOpen && (
        <AssetModal
          assetsList={assetsList}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
          selectedFaction={selectedFaction}
          setSelectedFaction={setSelectedFaction}
          onClose={handleCloseAssetModal}
          setSortBy={setSortBy} // Pass setSortBy as a prop to AssetModal
        />
      )}
    </div>
  );
};

export default ApesPage;
