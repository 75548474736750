import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
  }
`;

const Section = styled.section`
  width: 100vw;
  background-color: #202020;
  position: relative;
  color: #ebebeb;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ebebeb; // Adds a horizontal line at the top with specified color
`;

const Container = styled.div`
  width: 85%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.text};
  font-family: "Poppins", sans-serif;
  font-size: 10px;

  @media (max-width: 48em) {
    width: 80%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;

const Footer = () => {
  return (
    <>
      <GlobalStyle />
      <Section>
        <Container>
          <span>
            &copy; {new Date().getFullYear()} Apelantia. All rights reserved.
          </span>
        </Container>
      </Section>
    </>
  );
};

export default Footer;
