// utils/calculateAge.js
function calculateAge(birthdate) {
  // Parse the birthdate manually
  const birthYear = parseInt(birthdate.split("/")[0], 10);
  const birthMonth = parseInt(birthdate.split("/")[1], 10) - 1;
  const birthDay = parseInt(birthdate.split("/")[2], 10);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // Calculate the age based on the year difference
  let age = currentYear - birthYear;

  // Adjust if the current date is before the birthdate in the year
  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--;
  }

  return age;
}

module.exports = calculateAge;
