import React, { useEffect } from "react";
import "./ConnectWallet.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiConfig } from "wagmi";
import {
  RainbowKitProvider,
  getDefaultConfig,
  ConnectButton,
} from "@rainbow-me/rainbowkit";
import { useWallet } from "../contexts/WalletProvider";
import merge from "lodash.merge";
import "@rainbow-me/rainbowkit/styles.css";

const queryClient = new QueryClient();

const blastChain = {
  id: 81457,
  name: "Blast",
  network: "blast",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: { http: ["https://rpc.blast.io"] },
  },
  blockExplorers: {
    default: { name: "BlastScan", url: "https://blastscan.io" },
  },
};

const baseTheme = getDefaultConfig({
  appName: "RainbowKit demo",
  projectId: "2d210143252cfdb8e350ebf7bf30051c",
  chains: [blastChain],
  ssr: false,
});

const customTheme = merge(baseTheme.theme, {
  colors: {
    accentColor: "#ECBD3A",
    accentColorForeground: "#202020",
    actionButtonBorder: "#EBEBEB",
    actionButtonBorderMobile: "#EBEBEB",
    actionButtonSecondaryBackground: "#202020",
    closeButton: "#202020",
    closeButtonBackground: "#ECBD3A",
    connectButtonBackground: "#ECBD3A",
    connectButtonBackgroundError: "#ECBD3A",
    connectButtonInnerBackground: "#202020",
    connectButtonText: "#202020",
    connectButtonTextError: "#202020",
    connectionIndicator: "#202020",
    generalBorder: "#EBEBEB",
    generalBorderDim: "#EBEBEB",
    menuItemBackground: "#202020",
    modalBackdrop: "rgba(0,0,0,0.5)",
    modalBackground: "#202020",
    modalBorder: "#EBEBEB",
    modalText: "#EBEBEB",
    modalTextDim: "#EBEBEB",
    modalTextSecondary: "#ECBD3A",
    profileAction: "#ECBD3A",
    profileActionHover: "#202020",
    profileForeground: "#ECBD3A",
    selectedOptionBorder: "#EBEBEB",
    standby: "#EBEBEB",
  },
  fonts: {
    body: '"ThestralNeue", sans-serif',
  },
  radii: {
    actionButton: "10px",
    connectButton: "10px",
    menuButton: "10px",
    modal: "20px",
    modalMobile: "20px",
  },
  shadows: {
    connectButton: "0 0 0 2px #EBEBEB",
    dialog: "0 0 0 2px #EBEBEB",
    profileDetailsAction: "0 0 0 2px #EBEBEB",
    selectedOption: "0 0 0 2px #EBEBEB",
    selectedWallet: "0 0 0 2px #EBEBEB",
    walletLogo: "0 0 0 2px #EBEBEB",
  },
});

function ConnectWallet() {
  const { walletAddress, setWalletAddress } = useWallet();

  const handleWalletConnection = (address) => {
    console.log("ConnectWallet.js - Wallet connected:", address);
    setWalletAddress(address);
  };

  const handleWalletDisconnection = () => {
    console.log("ConnectWallet.js - Wallet disconnected");
    setWalletAddress(null);
  };

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      console.log("ConnectWallet.js - accountsChanged event:", accounts);
      if (accounts.length > 0) {
        handleWalletConnection(accounts[0]);
      } else {
        handleWalletDisconnection();
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);

  return (
    <WagmiConfig config={baseTheme}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={customTheme} chains={[blastChain]}>
          <div className="connect-wallet">
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button onClick={openConnectModal} type="button">
                            Connect Wallet
                          </button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button onClick={openChainModal} type="button">
                            Wrong network
                          </button>
                        );
                      }

                      return (
                        <div style={{ display: "flex", gap: 5 }}>
                          <button
                            onClick={openChainModal}
                            style={{ display: "flex", alignItems: "center" }}
                            type="button"
                          >
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 30,
                                  height: 30,
                                  borderRadius: 10,
                                  overflow: "hidden",
                                  marginRight: 10,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? "Chain icon"}
                                    src={chain.iconUrl}
                                    style={{ width: 30, height: 30 }}
                                  />
                                )}
                              </div>
                            )}
                            {chain.name}
                          </button>

                          <button onClick={openAccountModal} type="button">
                            {account.displayName}
                          </button>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}

export default ConnectWallet;
