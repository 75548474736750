import React, { createContext, useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import eventEmitter from "../utils/eventEmitter";

const WalletContext = createContext();

const TOKEN_CONTRACT_ADDRESS = "0xE66c7C14E7035Fe158dB1f55eFF8a2CE7ca4df7c";
const ERC721_ABI = ["function balanceOf(address owner) view returns (uint256)"];

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [hasComicPass, setHasComicPass] = useState(null);

  const handleWalletConnection = async (accounts) => {
    if (accounts.length === 0) {
      handleWalletDisconnection();
    } else {
      const address = accounts[0];
      console.log("WalletProvider - Wallet connected:", address);
      setWalletAddress(address);
      await checkNFT(address);
      eventEmitter.emit("walletConnected", address);
    }
  };

  const handleWalletDisconnection = () => {
    console.log("WalletProvider - Wallet disconnected");
    setWalletAddress(null);
    setHasComicPass(false);
    eventEmitter.emit("walletDisconnected");
  };

  const checkNFT = async (address) => {
    if (!address) return;

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        TOKEN_CONTRACT_ADDRESS,
        ERC721_ABI,
        provider
      );
      const balance = await contract.balanceOf(address);
      console.log(
        "WalletProvider - Token balance for address:",
        balance.toString()
      );

      const hasPass = balance.gt(0);
      setHasComicPass(hasPass);
    } catch (error) {
      console.error("WalletProvider - Error checking token ownership:", error);
      setHasComicPass(false);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleWalletConnection);

      window.ethereum
        .request({ method: "eth_accounts" })
        .then(handleWalletConnection)
        .catch((error) => {
          console.error(
            "WalletProvider - Error checking wallet connection:",
            error
          );
        });

      return () => {
        window.ethereum.removeListener(
          "accountsChanged",
          handleWalletConnection
        );
      };
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{ walletAddress, setWalletAddress, hasComicPass, setHasComicPass }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
