import React, { useLayoutEffect, useRef } from 'react'
import { useWindowScroll } from 'react-use';
import styled from 'styled-components'


const Up = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid #202020; /* Default stroke color */
  margin: 0;
  padding: 0;
  color: #202020; /* Black text by default */
  background-color: #ebebeb;
  z-index: 1000;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease,
    border-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #202020;
    color: #EBEBEB; /* White text on hover */
    border-color: #ebebeb; /* Stroke color on hover */
  }

  &:active {
    transform: scale(0.9);
  }
`;

const ScrollToTop = () => {

    const ref = useRef(null);
    const {y} = useWindowScroll();

    const scrollToTop = () => {

        let element = document.getElementById("navigation");
      
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      }

      useLayoutEffect(() => {
        if(y > 200){
            ref.current.style.display = "flex"
        }else{
            ref.current.style.display = "none"
        }
      }, [y])
  return (
    <Up  ref={ref}  onClick={() => scrollToTop()}>
        &#x2191;
    </Up>
  )
}

export default ScrollToTop