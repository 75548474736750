import React, { useState } from "react";
import "./Home.css";
import CoverImage from "../CoverImage";
import TypeWriterText from "../TypeWriterText";
import HomeBackground from "../../assets/homebackground.webp";
import { ReactComponent as LeftArrowIcon } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/right-arrow.svg";
import ApesGif from "../../assets/apes.gif";

const Home = () => {
  const [mintCount, setMintCount] = useState(1);

  const handleDecrease = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1);
    }
  };

  const handleIncrease = () => {
    if (mintCount < 3) {
      setMintCount(mintCount + 1);
    }
  };

  const handleMintClick = () => {
    window.open("https://blastr.xyz/", "_blank", "noopener,noreferrer");
  };

  const handleWhitepaperClick = () => {
    window.open(
      "https://apelantia.gitbook.io/apelantia",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <section className="Section" id="home">
      <div
        className="BackgroundImage"
        style={{ backgroundImage: `url(${HomeBackground})` }}
      />
      <div className="Container">
        <div className="Box ImageBox">
          <CoverImage />
        </div>
        <div className="Box RoundedBox">
          <div className="TopText">MINT AN APE ON BLASTR.XYZ</div>
          <div className="Circle">
            <img src={ApesGif} alt="Animated Apes" className="CircleGif" />
          </div>
          <div className="MintControls">
            <div className="ArrowControls">
              <button onClick={handleDecrease} className="ArrowButton">
                <LeftArrowIcon />
              </button>
              <div className="MintCount">{mintCount}</div>
              <button onClick={handleIncrease} className="ArrowButton">
                <RightArrowIcon />
              </button>
            </div>
            <button className="MintButton" onClick={handleMintClick}>
              Mint
            </button>
          </div>
          <button className="WhitepaperButton" onClick={handleWhitepaperClick}>
            WHITEPAPER
          </button>
        </div>
      </div>
    </section>
  );
};

export default Home;
