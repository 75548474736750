import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoImage from '../LOGO.webp';

const LogoWrapper = styled.div`
  img {
    max-width: 150px; // Adjust width as needed
    max-height: 75px; // Adjust height as needed
  }
`;

const Logo = () => {
  return (
    <LogoWrapper>
      <Link to="/">
        <img src={LogoImage} alt="Logo" />
      </Link>
    </LogoWrapper>
  )
}

export default Logo;
