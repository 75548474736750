import React from "react";
import Map from "../Map";
import "./MapPage.css";
import Roadmap from "./Roadmap";
import Footer from "../Footer";

const MapPage = () => {
  return (
    <div className="map-page">
      <Map />
      <Roadmap />
      <Footer />
    </div>
  );
};

export default MapPage;
