import React from "react";
import styled from "styled-components";

import img2 from "../../assets/Nfts/ape-1.webp";
import img4 from "../../assets/Nfts/ape-3.webp";

const Section = styled.section`
  min-height: 25vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  overflow: hidden;
`;

const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Item = styled.div`
  width: calc(20rem - 4vw);
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  z-index: 5;
  backdrop-filter: blur(4px);
  border: 2px solid ${(props) => props.theme.text};
  border-radius: 20px;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.carouselColor};
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  }
`;

const Name = styled.h2`
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
`;

const Position = styled.h2`
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  font-weight: 400;
`;

const MemberComponent = ({ img, name = " ", position = " ", onClick }) => {
  return (
    <Item onClick={onClick}>
      <ImageContainer>
        <img width={500} height={400} src={img} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const Team = () => {
  const handleAjClick = () => {
    window.open("https://x.com/itsBlastAj", "_blank", "noopener,noreferrer");
  };

  const handleSmokeClick = () => {
    window.open("https://x.com/sm0ke_101", "_blank", "noopener,noreferrer");
  };

  return (
    <Section id="team">
      <Title>Team</Title>
      <Container>
        <MemberComponent
          img={img4}
          name="Aj"
          position="Co-Founder / Dev"
          onClick={handleAjClick}
        />
        <MemberComponent
          img={img2}
          name="Smoke"
          position="Co-Founder / Loresmith"
          onClick={handleSmokeClick}
        />
      </Container>
    </Section>
  );
};

export default Team;
