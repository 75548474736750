import React from "react";
import MainPanel from "../MainPanel";
import SidePanel from "../SidePanel";
import "./PlayPage.css";
import Footer from "../Footer";

const PlayPage = () => {
  return (
    <div className="play-page-container">
      <MainPanel />
      <SidePanel />
    </div>
  );
};

export default PlayPage;
