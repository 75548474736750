import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Button from './Button';

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  width: 90%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;

  span {
    text-transform: uppercase;
    font-family: "font-family: 'Poppins', sans-serif;
  }
  .text-1 {
    color: #046307; /*Dark Green */
  }

  .text-2 {
    color: #d4af37; /* Royal gold */
  }

  .text-3 {
    color: #8a0303; /* Blood red */
  }

  .text-4 {
    color: Black;
  }

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
  @media (max-width: 40em) {
    width: 90%;
  }
`;
const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${props => `rgba(${props.theme.textRgba}, 0.6)`};
  font-weight:600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};

  }

  @media (max-width: 48em) { 
    align-self: center;
    text-align:center;
  }
  
`

const ButtonContainer = styled.div`
 width: 80%;
  align-self: flex-start;

  @media (max-width: 48em) { 
    align-self: center;
    text-align:center;

    button{
      margin: 0 auto;
    }
  }

`
const TypeWriterText = () => {
  const [showSecondTypewriter, setShowSecondTypewriter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSecondTypewriter(true);
    }, 6000); // 6000ms delay before showing the second typewriter

    return () => clearTimeout(timer); // Clear timeout on unmounting
  }, []);
  
  return (
    <>
      <Title>
        <Typewriter
          options={{
            autoStart: true,
            loop: false,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(`<span class="text-5">Welcome to Apelantia.</span>`)
              .pauseFor(2000)
              .start();
          }}
        />
        {showSecondTypewriter && (
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
              delay: 45,
              deleteSpeed: 25
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString(`<span class="text-1">Rebels.</span>`)
                .pauseFor(2000)
                .deleteAll()
                .typeString(`<span class="text-2">Royals.</span>`)
                .pauseFor(2000)
                .deleteAll()
                .typeString(`<span class="text-3">Mafia.</span>`)
                .pauseFor(2000)
                .deleteAll()
                .typeString(
                  `<span class="text-1">W</span>` +
                  `<span class="text-2">h</span>` +
                  `<span class="text-3">a</span>` +
                  `<span class="text-1">t</span> ` +
                  `<span class="text-2">w</span>` +
                  `<span class="text-3">i</span>` +
                  `<span class="text-1">l</span>` +
                  `<span class="text-2">l</span> ` +
                  `<span class="text-3">y</span>` +
                  `<span class="text-1">o</span>` +
                  `<span class="text-2">u</span> ` +
                  `<span class="text-3">c</span>` +
                  `<span class="text-1">h</span>` +
                  `<span class="text-2">o</span>` +
                  `<span class="text-3">o</span>` +
                  `<span class="text-1">s</span>` +
                  `<span class="text-2">e</span>` +
                  `<span class="text-3">?</span>`
                )
                .pauseFor(4000)
                .deleteAll()
                .start();
            }}
          />
        )}
      </Title>
      <SubTitle>Unleash Your Inner Ape: Fight, Dream, Conquer.</SubTitle>
      <ButtonContainer>
        <Button text="Explore" link="#about" />
      </ButtonContainer>
    </>
  );
};

export default TypeWriterText;
