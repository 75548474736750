import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala";
import "@fontsource/sora";
import "@fontsource/poppins"; // Import Poppins font

const GlobalStyles = createGlobalStyle`

html {
    scroll-behavior: smooth;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif; // Use Poppins as the global font
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

`;

export default GlobalStyles;
