import React from "react";
import App from "./App";
import "../node_modules/normalize.css/normalize.css";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { WalletProvider } from "./contexts/WalletProvider"; // Import WalletProvider

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WalletProvider>
        {" "}
        {/* Wrap App with WalletProvider */}
        <App />
      </WalletProvider>
    </BrowserRouter>
  </React.StrictMode>
);
