const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "").replace(".webp", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context("../assets/thumbnails", false, /\.webp$/)
);

export default images;
