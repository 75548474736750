import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";
import apesData from "../Encrypted.json"; // Import the JSON data directly
import images from "../utils/ImportImages"; // Import the images

const SearchBar = () => {
  const [input, setInput] = useState("");
  const [debouncedInput, setDebouncedInput] = useState(input);
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false); // State to track if no results were found
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const navigate = useNavigate();
  const searchBarContainerRef = useRef(null);

  // Debounce the input to prevent searching on every keystroke
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInput(input);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [input]);

  // Memoize the filtered results to prevent unnecessary recalculations
  const filteredApes = useMemo(() => {
    if (debouncedInput.length > 0) {
      const searchTerm = debouncedInput.toLowerCase();

      const results = apesData
        .filter((ape) => {
          const apeNameLower = ape.name.toLowerCase();
          return (
            apeNameLower.includes(searchTerm) ||
            ape.id.toString() === searchTerm
          );
        })
        .map((ape) => {
          const [firstName, ...rest] = ape.name.split(" ");
          const lastName = rest.join(" ");
          const firstNameLower = firstName.toLowerCase();
          const lastNameLower = lastName.toLowerCase();

          let priority = 2; // Default priority

          if (firstNameLower.startsWith(searchTerm)) {
            priority = 0; // Highest priority
          } else if (lastNameLower.startsWith(searchTerm)) {
            priority = 1; // Medium priority
          }

          return { ...ape, priority };
        })
        .sort((a, b) => a.priority - b.priority);

      return results;
    } else {
      return [];
    }
  }, [debouncedInput]);

  // Update suggestions and noResults state based on filtered results
  useEffect(() => {
    if (filteredApes.length > 0) {
      setSuggestions(filteredApes.slice(0, 30)); // Limit to 30 results
      setNoResults(false);
    } else if (debouncedInput.length > 0) {
      setSuggestions([]);
      setNoResults(true);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
    setHighlightedIndex(-1); // Reset highlighted index when suggestions change
  }, [filteredApes, debouncedInput]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarContainerRef.current &&
        !searchBarContainerRef.current.contains(event.target)
      ) {
        setSuggestions([]);
        setNoResults(false); // Reset no results state when clicking outside
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarContainerRef]);

  // Navigate to the selected suggestion
  const navigateToSuggestion = (suggestion) => {
    navigate(`/profile/${suggestion.id}`);
    setSuggestions([]);
    setNoResults(false); // Reset no results state on selection
    setHighlightedIndex(-1); // Reset highlighted index
    setInput(""); // Optionally clear input
  };

  return (
    <div className="search-bar-container" ref={searchBarContainerRef}>
      <input
        type="text"
        className="search-bar"
        placeholder="Search Ape"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
              prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
            );
          } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : -1
            );
          } else if (e.key === "Enter") {
            if (
              highlightedIndex >= 0 &&
              highlightedIndex < suggestions.length
            ) {
              navigateToSuggestion(suggestions[highlightedIndex]);
            } else if (suggestions.length > 0) {
              navigateToSuggestion(suggestions[0]);
            }
          }
        }}
      />
      {noResults && (
        <div className="no-results">No results found</div> // Display when no results are found
      )}
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li
              key={suggestion.id}
              onClick={() => navigateToSuggestion(suggestion)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigateToSuggestion(suggestion);
                }
              }}
              tabIndex="0"
              style={
                index === highlightedIndex ? { backgroundColor: "#303030" } : {}
              }
            >
              <img
                src={
                  images[suggestion.id]
                    ? images[suggestion.id]
                    : `https://storage.googleapis.com/apelantia/pixelated/${suggestion.id}.png`
                }
                alt={suggestion.name}
                className="suggestion-image"
              />
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
