import React, { useState } from "react";
import "./Map.css";
import MapImage from "../assets/petajustmap.svg";
import Peta from "./peta";

const Map = () => {
  const [outlineVisible, setOutlineVisible] = useState(true);

  return (
    <div className="map-wrapper">
      <div className="map-container">
        <div className="svg-map-object">
          <img src={MapImage} alt="Map" style={{ width: '100%', height: '100%' }} />
        </div>
        {outlineVisible && (
          <div className="svg-map-object">
            <Peta />
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
