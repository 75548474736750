import React from "react";
import NFTCard from "./NFTCard";
import "./NFTList.css";

const NFTList = ({ nftCardsData }) => {
  return (
    <div className="nft-list-container">
      {nftCardsData.map((card, index) => (
        <NFTCard
          key={index}
          imageUrl={card.imageUrl}
          name={card.name}
          id={card.id}
          age={card.age}
          faction={card.faction} // Pass faction to NFTCard
          role={card.role} // Pass role to NFTCard
        />
      ))}
    </div>
  );
};

export default NFTList;
